<script setup lang="ts">
import type { PropType } from 'vue'
import { ImageCropSource } from '~/enums'
import type { ImageMode } from "~/types";

const props = defineProps({
	urlServer: {
		type: String,
		default: undefined
	},
	urlCdn: {
		type: String,
		default: undefined
	},
	urls: {
		type: Array as PropType<any[]>,
		default: undefined
	},
	width: {
		type: Number,
		default: undefined,
	},
	height: {
		type: Number,
		default: undefined,
	},
	crop: {
		type: String,
		default: 'c'
	},
	mode: {
		type: String as PropType<ImageMode>,
		default: 'crop'
	},
	alt: {
		type: String,
		requited: true
	},
	serverMode: {
		type: String,
		default: undefined
	},
	density: {
		type: Number,
		default: 3
	},
	imageClass: {
		type: String,
		default: undefined
	}
})

const types = [
	{ mime: 'image/webp', ext: 'webp' },
	{ mime: 'image/avif', ext: 'avif' },
]

const src = (ext: string, density: number = 1) => {
	const serverMode = props.serverMode ?? useRuntimeConfig().public.IMAGE_SERVER_MODE
	switch (serverMode) {
		case ImageCropSource.Cdn:
			return srcCdn(ext, density)
		default:
			return srcServer(ext, density)
	}
}

const srcCdn = (ext: string, density: number = 1) => {
	const params = {
		fmt: ext,
		width: (props.width ?? 0) * density,
		height: (props.height ?? 0) * density,
		fit: 'fit',
		quality: 90
	} as any
	
	return props.urlCdn + '?' + new URLSearchParams(params).toString()
}

const srcServer = (ext: string, density: number = 1) => {
	if (props.urls?.length) {
		return props.urls[0][ext]
	}
	return props.urlServer + '/' + (props.width ?? 0) * density + 'x' + (props.height ?? 0) * density + 'x' + getCrop() + '_image.' + ext
}

const srcset = (ext: string) => {
	if (props.urls?.length) {
		return srcsetUrls(ext)
	}
	
	if (!props.density) {
		return src(ext)
	}
	
	const tmp = []
	
	for (let density = 1; density <= props.density; density++) {
		tmp.push(src(ext, density) + ' ' + density + 'x')
	}
	
	return tmp.join(', ')
}

const srcsetUrls = (ext: string) => {
	
	const tmp = []
	
	if (props.urls?.length) {
		if (!props.density) {
			tmp.push(props.urls[0][ext])
		}
		else {
			for (const [index, url] of props.urls.entries()) {
				if (index + 1 <= props.density) {
					tmp.push(url[ext] + ' ' + (index + 1) + 'x')
				}
			}
		}
	}
	
	return tmp.join(', ')
}

const getTypes = () => {
	if (props.urls?.length) {
		return [types[0]]
	}
	return types
}

const getCrop = () => {
	if (props.mode === 'resize') {
		return 'bw'
	}
	return props.crop
}

</script>

<template>
	<template v-if="urlServer || urlCdn || urls?.length">
		<picture v-not-lazy
			v-bind="$attrs"
			class="relative flex justify-center items-center"
		>
			<source
				v-for="(type, index) in getTypes()"
				:key="index"
				:data-srcset="srcset(type.ext)"
				:type="type.mime"
			>
			<img
				loading="lazy"
				decoding="async"
				:src="src('webp')"
				:srcset="srcset('webp')"
				:alt="alt"
				class="w-full h-full"
				:class="{
					'object-cover': mode === 'crop',
					'object-contain': mode === 'resize'
				}"
			>
		</picture>
	</template>
</template>